<template>
  <b-row>
    <b-col cols="12" md="9">
      <b-form @submit.prevent="savePost" v-if="content" class="mb-4">
        <b-input size="lg" v-model="content.title" class="mb-2" placeholder="Title" required></b-input>
        <b-textarea v-model="content.summary" class="mb-2" placeholder="Summary" required></b-textarea>
        <b-file v-model="contentFeaturedImg" class="mb-2" @change="onFileChange" placeholder="Featured Image"></b-file>
        <div v-if="contentFeaturedImgUrl" class="mb-2 animated fadeIn">
          <b-img fluid-grow :src="contentFeaturedImgUrl" />
          <div class="text-right"><a href="#" @click.prevent="removeFeaturedImage">Remove Featured Image</a></div>
        </div>
        <RichTextEditor pageSlug="happening-now" :content="content.content" @input="setContent" />
        <!-- ckeditor 
          id="page-content"
          :editor="editor" 
          v-model="content.content" 
          placeholder="Content"
          :config="editorConfig"
        ></ckeditor -->
        <b-row class="mt-2">
          <b-col>
            <b-input-group prepend="Post Date">
              <b-form-input type="date" v-model="content.dateStart"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
            <b-input-group prepend="Exp Date">
            <b-form-input type="date" v-model="content.dateEnd"></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
            <b-form-checkbox
              v-if="content.id"
              id="checkbox-archived"
              v-model="content.archived"
              name="checkbox-archived"
              :value="true"
              :unchecked-value="false"
            >
              Archived
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="!2" lg="6"><b-btn :to="{name: 'happeningNow'}" variant="danger">Cancel</b-btn></b-col>
          <b-col cols="!2" lg="6" class="text-right"><b-btn type="submit" variant="success">Save Post</b-btn></b-col>
        </b-row>
      </b-form>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions } from 'vuex'
import { constants } from '@/shared/constants'
import RichTextEditor from '@/components/RichTextEditor'
export default {
  data () {
    return {
      content: {
        options: {},
        dateStart: null,
        dateEnd: null,
        content: '',
        archived: false
      },
      contentFeaturedImg: null,
      contentFeaturedImgUrl: null,
      contentFeaturedImgObj: null
    }
  },
  methods: {
    ...mapActions([
      'getContent',
      'postContent',
      'putContent',
      'resetContent',
      'postMedia'
    ]),
    setContent (val) {
      this.content.content = val
    },
    removeFeaturedImage (e) {
      this.content.options = {}
      this.contentFeaturedImg = null
      this.contentFeaturedImgUrl = null
      this.contentFeaturedImgObj = null
    },
    onFileChange(e) {
      if (typeof e.target.files[0] === 'undefined') {
        this.contentFeaturedImgUrl = null
        return
      }
      let file = e.target.files[0]
      this.contentFeaturedImgUrl = URL.createObjectURL(file)
    },
    async saveFeaturedImage () {
      if (this.contentFeaturedImg === null) {
        return;
      }
      let media = {
        slug: this.currentBusiness.uriName + '/happening-now',
        file: this.contentFeaturedImg,
        user: this.currentUser.id,
        business: this.currentBusiness.id
      }
      return await this.postMedia(media).then(resp => {
        this.contentFeaturedImgObj = {
          src: constants.MEDIA_URL + resp.contentUrl
        }
        return resp
      }, error => {
        console.log(error)
        this.showError({
          message: error
        })
      })
    },
    saveContent () {
      if (typeof this.content.id !== 'undefined') {
        let content = { ...this.content }
        content.author = content.author['@id']
        this.content.slug = this.toSlug(this.content.title)
        this.putContent(content).then(() => {
          this.showSuccess()
        }, error => {
          this.showError()
        })
      } else {
        this.content.business = this.currentBusiness['@id']
        this.content.author = this.currentUser['@id']
        this.content.type = 'happening_post'
        this.content.slug = this.toSlug(this.content.title)
        this.postContent(this.content).then(resp => {
          this.content = resp
          this.showSuccess()
        }, error => {
          this.showError()
        })
      }
    },
    async savePost (e) {
      if (this.contentFeaturedImg !== null) {
        // add/update featured image
        await this.saveFeaturedImage().then(() => {
          if (this.contentFeaturedImgObj !== null) {
            if (this.content.options === null) {
              this.content.options = {}
            }
            this.content.options.featuredImage = this.contentFeaturedImgObj
          }
          // this.saveContent()
        })
        // return
      }
      this.saveContent()
    },
  },
  created () {
    if (this.$route.params.id) {
      this.getContent({
        id: this.$route.params.id,
        business: this.currentBusiness.id,
        type: constants.CONTENT_TYPE_HAPPENING_NOW
      }).then(resp => {
        this.content = this.$store.getters.content[0]
        if (Array.isArray(this.content.options)) {
          this.content.options = {}
        }
        if (this.content.dateStart) {
          this.content.dateStart = this.$moment(this.content.dateStart).format('YYYY-MM-DD')
        }
        if (this.content.dateEnd) {
          this.content.dateEnd = this.$moment(this.content.dateEnd).format('YYYY-MM-DD')
        }
        // set featured image
        let options = this.content.options
        if (options !== null && typeof options.featuredImage !== 'undefined') {
          this.contentFeaturedImgUrl = options.featuredImage.src
        }
      })
    }
  },
  mounted () {
    if (!this.content.dateStart) {
      this.content.dateStart = this.$moment().format('YYYY-MM-DD')
    }
  },
  destroyed () {
    this.resetContent()
  },
  components: { RichTextEditor }
}
</script>

